<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";
import {required} from "vuelidate/lib/validators";
import {paginationHelper} from "@/helpers/pagination-helper";
import EcatCkeditor from "../../../components/ecat-ckeditor.vue";

/**
 * News Component
 */
export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },
  components: {
    EcatCkeditor,
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        elements: []
      },

      inputPage: "",

      users: null,

      news: {
        defaultImage: "",
        title: "",
        content: ""
      },

      visibleModal: false,
      imageFile: null,
      imageExtension: ""
    };
  },

  validations: {
    imageFile: { required },
    news: {
      title: { required },
      content: { required }
    }
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('menuitems.admin.list.news'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "defaultImage", label: this.$t('news.image') },
        { key: "title", label: this.$t('news.title') },
        { key: "content", label: this.$t('news.content') },
        { key: "status", label: "Status" },
        { key: "author", label: this.$t('news.author') },
        { key: "createdAt", label: this.$t('news.createdAt'), formatter: value => {
            const dateOptions = { timeZone: 'Europe/Warsaw', weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };

            const dateFormatter = new Intl.DateTimeFormat( this.$store.getters["translation/getCurrentLocaleOrFallback"], dateOptions);
            return dateFormatter.format(new Date(value));
          }
        },
        { key: "action", label: this.$t('table.actions') }
      ]
    },

    async loadNews() {
      const result = await axios.get(`/news`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      });

      this.table.elements = result.data
      this.table.totalRows = this.table.elements.length;
      this.table.rows = this.table.elements.length

      if (this.mounted) {
        this.$refs.table.refresh();
      }
    },

    async loadUsers() {
      if (this.table.elements.length === 0) {
        return;
      }

      const ids = [];
      for (let shop of this.table.elements) {
        ids.push(shop.author);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const result = await axios.post(`/user`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.users = new Map(result.data.map((obj) => [obj.id, obj]));
    },

    getUser(userId) {
      if (!this.users) {
        return null;
      }

      return this.users.get(userId);
    },

    changeStatus(news) {
      this.submitted = true;

      const newStatus = !news.status;
      const json = JSON.stringify({
        "id": news.id,
        "status": newStatus
      });

      swalHelper.yesOrNo(() => {
        axios.post(`/news/change-status`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(() => {
          this.$bvToast.toast(this.$t('news.change-status.success'), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          for (const index in this.table.elements) {
            if (this.table.elements[index].id === news.id) {
              this.table.elements[index].status = newStatus;
              break;
            }
          }

          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        })
      })
    },

    deleteNews(news) {
      this.submitted = true;

      const json = JSON.stringify({
        "id": news.id
      });

      swalHelper.yesOrNo(() => {
        axios.delete(`/news`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: json
        }).then(() => {
          this.$bvToast.toast(this.$t('news.delete.success'), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          this.table.elements = this.table.elements.filter(element => element.id !== news.id)
          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        });
      });
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.visibleModal = false;
      this.news = {
        "defaultImage": "",
        "title": "",
        "content": ""
      };
    },

    editNews(news) {
      this.news = Object.assign({}, news);
      this.visibleModal = true
    },

    async createOrEditNews() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.news.$touch();
      if (this.$v.news.$invalid) {
        return;
      }

      if (this.imageFile) {
        await this.upload()
      }

      const json = JSON.stringify(this.news);

      axios.put(`/news`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then((result) => {
        this.$bvToast.toast(this.$t(this.news.id ? "news.edit.success" : "news.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        if (this.news.id) {
          for (const index in this.table.elements) {
            if (this.table.elements[index].id === this.news.id) {
              this.table.elements[index] = this.news;
              break;
            }
          }

          this.$refs.table.refresh();
        } else {
          this.table.elements.push(result.data)

          if (this.users === null) {
            this.loadUsers()
          }
        }

        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    uploadImage(input) {
      const image = input.target.files[0];
      this.imageFile = image;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = event => {
        const dataUrl = event.target.result;
        this.imageExtension = dataUrl.split(';')[0].split('/')[1];
      };
    },

    async upload() {
      const formData = new FormData();
      formData.append('file', this.imageFile);
      formData.append('extension', this.imageExtension)

      try {
        const result = await axios.post(`/cdn/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.news.defaultImage = result.data.url
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

  },

  async created() {
    try {
      await this.loadNews()
      await this.loadUsers()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('menuitems.admin.list.news')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a class="clickable-element btn btn-success mb-2 mx-2" @click="visibleModal = true">
                <i class="mdi mdi-plus mr-2"></i> {{ $t('news.create.button') }}
              </a>
            </div>

                <div class="py-4">
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          {{ $t('table.show') }}&nbsp;
                          <b-form-select v-model="table.perPage" size="sm" :options="table.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center">
                          {{ $t('table.search') }}
                          <b-form-input
                              v-model="table.filter"
                              type="search"
                              class="form-control form-control-sm ml-2"
                              @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="float-right">
                        <label class="d-inline-flex align-items-center mx-2">
                          <b-form-input v-model="inputPage" class="form-control form-control-sm ml-2" />
                        </label>
                        <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, inputPage)">{{ $t('table.go-to-page') }}</b-button>
                      </div>
                    </div>
                  </div>

                  <div class="row py-3">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <b-table
                        ref="table"
                        :items="table.elements"
                        :fields="getFields()"
                        responsive="sm"
                        :per-page="table.perPage"
                        :current-page="table.currentPage"
                        :sort-by.sync="table.sortBy"
                        :sort-desc.sync="table.sortDesc"

                        :filter-included-fields="table.filterOn"
                        @filtered="elements => paginationHelper.onFiltered(table, elements)"
                        :busy.sync="table.isBusy"
                        :empty-text="$t('message.no-elements')"
                        :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                      <div slot="table-busy" class="text-center">
                        <h5>{{ $t('message.loading') }}</h5>
                        <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
                      </div>

                      <template v-slot:cell(defaultImage)="data">
                        <img :src="data.item.defaultImage" alt="default image" class="rounded avatar-lg">
                      </template>

                      <template v-slot:cell(content)="data">
                        <div v-html="data.item.content" />
                      </template>

                      <template v-slot:cell(status)="data">
                        <span :class="data.item.status ? 'badge badge-soft-success' : 'badge badge-soft-danger'" class="font-size-11"> {{ $t(data.item.status ? 'table.active' : 'table.inactive') }}</span>
                      </template>

                      <template v-slot:cell(author)="data">
                        <div :set="user = getUser(data.item.author)">
                          <template v-if="user">
                            {{ user.firstName }} {{ user.lastName }}
                          </template>
                        </div>
                      </template>

                      <template v-slot:cell(action)="data">
                        <div style="width: 120px;">
                          <a @click="editNews(data.item)" class="clickable-element mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                          <a @click="changeStatus(data.item)" class="clickable-element mr-3 text-primary"><i class="font-size-18" :class="data.item.status ? 'fa fa-toggle-on' : 'fa fa-toggle-off'"></i></a>
                          <a @click="deleteNews(data.item)" class="clickable-element text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a>
                        </div>
                      </template>
                    </b-table>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="float-left">
                        <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(table), 'elements': paginationHelper.getElementsCount(table), 'all': table.totalRows }) }}</p>
                      </div>

                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        size="xl"
        :scrollable="true"
        v-model="visibleModal"
        :title="$t(news.id ? 'news.edit.title' : 'news.create.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>
        <div class="form-group">
          <label for="image">{{ $t('news.image') }}</label>
          <br>

          <input accept="image/png,image/jpeg,image/jpg,image/gif,image/webp" type="file" id="image" @change="uploadImage" :class="{ 'is-invalid': $v.imageFile.$error }">
          <div v-if="!$v.imageFile.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="title">{{ $t('news.title') }}</label>
          <input id="title" v-model="news.title" type="text" name="title" class="form-control" :class="{ 'is-invalid': submitted && $v.news.title.$error }" />
          <div v-if="submitted && !$v.news.title.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group ck-body-wrapper">
          <label for="content">{{ $t('news.content') }}</label>
          <ecat-ckeditor v-model="news.content" :ecatStyle="{ 'is-invalid': submitted && $v.news.content.$error }" />
          <div v-if="submitted && !$v.news.content.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <p v-if="!news.id" class="mb-2 text-danger">{{ $t('news.warning') }}</p>

        <div class="text-right">
          <b-button @click="createOrEditNews" variant="success">{{ $t(news.id ? 'news.edit.button' : 'news.create.button') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>